// Application Shell
import {Shell} from '@/entryPoints/b2c/shell';

// Vue components
import RssBlogFeed from '@/components/blog-feed-container/RssBlogFeed';
import ElpLocallyWidget from '@/components/elp-widgets/ElpLocallyWidget';
import ElpRentBikeWidget from '@/components/elp-widgets/ElpRentBikeWidget';
import EnhancedLocatorPageContainer from '@/components/containers/EnhancedLocatorPageContainer.vue';
import EnhancedLocatorPageMapContainer from '@/components/mapbox/containers/EnhancedLocatorPageMapContainer.vue';
import ImageComponent from '@/components/ImageComponent';

// Legacy Plugins
import '@/vendor/owl.carousel.min.js';
import '@/vendor/owl.carousel.plugins.js';
import '@/vendor/acc.storefinder';

// Legacy Modules
import slider from '@/modules/slider';

// Styling
import '@sassRoot/entryPoints/b2c/elp.scss';

class Elp extends Shell {
  loadPlugins() {
    // Load common plugins from Shell
    super.loadPlugins();
  }

  preLoadInits() {
    super.preLoadInits();

    window.addEventListener('init-vue-dynamic-content', () => {
      const domElements = document.querySelectorAll('[data-vue-dynamic-content]');
      if (!domElements || !domElements.length) {
        return;
      }

      domElements.forEach((el) => {
        new this.vue({
          el: el,
          components: {
            ImageComponent,
          },
        });
      });
    });
  }
}

const elp = new Elp({
  ElpLocallyWidget,
  RssBlogFeed,
  ElpRentBikeWidget,
  EnhancedLocatorPageContainer,
  EnhancedLocatorPageMapContainer,
});
elp.preLoadInits();
elp.initializeVue();

// Legacy foundation initialization

$(window).on('load', () => {
  $(document).foundation();
  elp.globalDocumentReadyInits();
});

$(window).on('load', () => {
  elp.globalOnLoadInits();

  // setting up owl sliders
  const sliders = $('[data-owl-slider]');
  window.sliderRefs = [];
  sliders.each((i, s) => {
    window.sliderRefs.push(slider($(s)));
  });
});
